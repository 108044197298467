import React, { useState, useEffect } from "react";
import { graphql, navigate } from 'gatsby'
import Helmet from "react-helmet"
import { Button, Form, Container, Row, Col, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/grunticon.scss";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import BreadCrumbs from '../Components/PropertyDetails/BackButton/BreadCrumbs';
import Select from "react-dropdown-select"

import SEO from "../Components/Seo/seo";
const ReviewDetails = (props) => {
  const GQLPage = props.data.glstrapi?.ourOffice;
  const GQLOffices = props.data.glstrapi?.ourOffices;
  const metadesc = "Our property experts in " + GQLPage.Name + " offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance in your property journey.";
  const [options, setOptions] = useState([]);

  useEffect(() => {

    let optionval = []

    GQLOffices.map(val => {
      if (val.Elfsight_Code) {
        optionval.push({ value: val.URL, label: val.Name })
      }

    })


    setOptions(optionval)
  }, [GQLOffices])
  const reviewDetail = (e) => {
    if (e[0].value) {
      navigate(`/about/customer-reviews/${e[0].value}/`)
    }
  }
  return (
    <React.Fragment>
      <Helmet>
        <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
      </Helmet>
      <div id={`body-bg-default`} className={`wrapper  office-details ${GQLPage.URL}`}>
        <div className="home-header"><Header /></div>
        <SEO title={GQLPage.Meta_Title ? GQLPage.Meta_Title : "Estate Agents in " + GQLPage.Name} description={metadesc ? metadesc : GQLPage.Meta_Description} />
        <BreadCrumbs baselabel="Customer Reviews" baseurl="/about/customer-reviews/" style="office-breadcrumbs" itemlabel={GQLPage.Name} isReview={true} />
        <section className="elfsight-widget">
          <Container>
            <Row>
              <Col lg={3} className="elfsight-selectbox">
                <Select placeholder="Choose your local branch" searchable={false} options={options} onChange={(reviewDetail)} values={[{value: GQLPage.URL, label: GQLPage.Name}]}/>
              </Col>
            </Row>
            <Row>
              <Col lg={12} >
                <div class={GQLPage.Elfsight_Code} data-elfsight-app-lazy></div>
              </Col>
            </Row>
          </Container>

        </section>
        <Footer searchtype="officedetails" area={GQLPage.Name} guide={GQLPage.Living_Module} />
      </div>
    </React.Fragment>
  )
}
export default ReviewDetails;
export const reviewQuery = graphql`
query ReviewQuery($id: ID!) {
  glstrapi {
    ourOffices {
      id
      Name
      URL
      Meta_Title
      Meta_Description
      Office_Address
      Sales_Phone_Number
      Lettings_Phone_Number
      Elfsight_Code
      Latitude
      Longitude
      Tile_Image {
        url
        alternativeText
      }
      imagetransforms
    }
    ourOffice(id: $id) {    
        id
        Name
        URL
        Banner_Image {
          url
          alternativeText
        }
        Meta_Title
        Meta_Description
        Office_Address
        Elfsight_Code
        Sales_Phone_Number
        Lettings_Phone_Number
        Sales_Email_Address
        Lettings_Email_Address
        About_Office
        Get_Directions_Link
        Office_CRM
        Office_Stats_Title
        Office_Stats_List {
          Stats
          Stats_Title
        }
        Office_CTAs {
          CTA_Link {
            Primary_URL
          }
          CTA_Label
          CTA_Custom_Link
        }
        Opening_Hour_Image {
          url
          alternativeText
        }
        Office_Opening_Hours {
          Day
          Time
        }
        Office_Useful_Links {
            Useful_Link_Menu {
                Primary_URL
            }
            Useful_Link_Label
            Custom_Useful_Link
        }
        Living_Module {
          id
          Living_Module_Title
          Living_Module_Intro
          Living_Module_Info {
            Info_List_Title
            Info_List_Value
          }
          Living_Module_Image {
            alternativeText
            url
          }
          Select_Areaguide {
            Name
            URL
           }
        }
        Longitude
        Latitude
        Office_FAQs {
          id
          FAQ_Title
          FAQ_Content
        }
        imagetransforms
        teams {
          id
          Name
          URL
          Designation
          Staff_Image {
            url
            alternativeText
          }
          imagetransforms
        }
      }
}
}`

